<template>
  <component :is="isHeader ? 'header' : 'div'" class="top">
    <nav class="header row">
      <BackIcon
        v-if="back"
        class="elm-mobile"
        :isButton="true"
        @action="goBack"
      />
      <div class="service-branding row elm-desktop">
        <BackIcon v-if="back" :isButton="true" @action="goBack" />
        <OficinasCaetanoRetailLogoPT v-if="country == 'pt'" />
        <OficinasCaetanoRetailLogoES v-else-if="country == 'es'" />
      </div>
      <div v-if="!carInfo" class="service-branding row elm-mobile">
        <OficinasCaetanoRetailLogoPT />
        <LogoBrand v-if="showLogoBrand" :logo="brandLogo" />
      </div>
      <CarInfoHeader v-if="carInfo" class="car-info" />
      <div v-if="carInfo" class="elm-mobile"></div>
      <div class="progress-bar-container elm-desktop" v-if="back">
        <div class="progress-bar">
          <div class="container">
            <ul class="progressbar">
              <li
                v-for="(step, index) in steps"
                :key="step.name"
                :class="{
                  active: index <= currentStepIndex,
                  previous: index < currentStepIndex,
                }"
                class="progress-item cursor-pointer"
                @click="navigateToStep(index)"
              >
                <div class="step-name">
                  {{ step.name }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <LogoBrand
        class="elm-desktop"
        :logo="brandLogoWithTitle"
        @click="redirectSeo"
      />
    </nav>
    <br />
    <UpdatedResume
      screen="mobile"
      v-if="mobileResume"
      :dealer="dealer"
      :services="services"
      :schedule="schedule"
      :mobility="mobility"
      :wash="wash"
    />
  </component>
</template>

<script>
import BackIcon from "@/assets/icons/BackIcon.vue";
import CarInfoHeader from "@/components/UpdatedResume/CarInfoHeader.vue";
import UpdatedResume from "@/components/UpdatedResume/UpdatedResume.vue";

import LogoBrand from "@/assets/LogoBrand.vue";
import OficinasCaetanoRetailLogoES from "@/assets/OficinasCaetanoRetailLogoES.vue";
import OficinasCaetanoRetailLogoPT from "@/assets/OficinasCaetanoRetailLogoPT.vue";

import { translateString } from "@/functions/functions.js";

import { mapState } from "vuex";

import { country } from "@/services/country";

export default {
  name: "Header",
  components: {
    BackIcon,
    CarInfoHeader,
    UpdatedResume,
    LogoBrand,
    OficinasCaetanoRetailLogoPT,
    OficinasCaetanoRetailLogoES,
  },
  props: {
    back: { type: Boolean, default: false },
    mobileResume: { type: Boolean, default: false },
    carInfo: { type: Boolean, default: false },
    dealer: { type: Boolean, default: false },
    services: { type: Boolean, default: false },
    mobility: { type: Boolean, default: false },
    wash: { type: Boolean, default: false },
    schedule: { type: Boolean, default: false },
    hasBkgImg: { type: Boolean, default: false },
    isHeader: { type: Boolean, default: false },
    showLogoBrand: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(["currentStepIndex"]), // vou mapear currentStepIndex do Vuex
    brandLogoWithTitle() {
      //Estou a criar a logo + a tag title com o titulo dinamico.
      const titleTag = `<title>${this.$store.state.seoTitle}</title>`;
      return this.$store.state.brandLogo.replace("<svg", `${titleTag}<svg`);
    },
  },
  data() {
    return {
      country,

      caetanoServiceLogo: require("@/assets/caetano-service-logo.png"),
      brandLogo: this.$store.state.brandLogo,
      dealerName: this.$store.state.dealerSelected.dealerName,
      dealerScreen: "",
      servicesTotal: "",
      servicesScreen: "",
      scheduleSelected: "",
      scheduleScreen: "",
      hasCG: this.$store.state.hasCG,
      cgVehicles: this.$store.state.cgVehicles,

      steps: [
        { name: translateString("init"), route: "Homepage" },
        { name: translateString("vehicle"), route: "CarSelection" },
        { name: translateString("dealer"), route: "DealerSelection" },
        { name: translateString("services"), route: "Services" },
        { name: translateString("dateHour"), route: "Schedule" },
        { name: translateString("dates"), route: "ContactInfo" },
        { name: translateString("resume"), route: "Resume" },
      ],
    };
  },
  watch: {
    $route(to) {
      /*  this.currentStepIndex = this.steps.findIndex(
         (step) => step.route === to.name
       ); */
      const newIndex = this.steps.findIndex((step) => step.route === to.name);
      this.$store.dispatch("setCurrentStepIndex", newIndex);
    },
  },
  methods: {
    translateString,
    redirectSeo() {
      // criar backlink para o site, SEO, click na logo da marca
      var currentUrl = window.location.hostname;
      if(this.$store.state.firstPath != "") {
        currentUrl += '_' + this.$store.state.firstPath;
        currentUrl = currentUrl.toLowerCase();
      }
      const canonicalUrls = this.$store.state.backLinkSeo;
      const redirectUrl = canonicalUrls[currentUrl]
        ? canonicalUrls[currentUrl]
        : (country == 'pt' ? "https://oficinas.caetanoretail.pt" : "https://cita-taller.caetanoretail.es" );
      if (redirectUrl) {
        window.open(redirectUrl, "_blank");
      }
    },
    goBack() {
      const name = this.$route.name;
      switch (name) {
        case "Resume":
          this.$store.dispatch(
            "setCurrentStepIndex",
            this.currentStepIndex - 1
          );
          this.$router.push({ name: "ContactInfo" });
          break;
        case "ContactInfo":
          this.$store.dispatch(
            "setCurrentStepIndex",
            this.currentStepIndex - 1
          );
          this.$router.push({ name: "Schedule" });
          break;
        case "Schedule":
          this.$store.dispatch(
            "setCurrentStepIndex",
            this.currentStepIndex - 1
          );
          this.$router.push({ name: "MobilityServices" });
          break;
        case "WashServices":
          this.$router.push({ name: "Services" });
          break;
        case "MobilityServices":
          this.$router.push({ name: "WashServices" });
          break;
        case "Services":
          this.$store.dispatch(
            "setCurrentStepIndex",
            this.currentStepIndex - 1
          );
          this.$router.push({ name: "DealerSelection" });
          break;
        case "DealerSelection":
          this.$store.dispatch(
            "setCurrentStepIndex",
            this.currentStepIndex - 1
          );
          this.$router.push({ name: "CarConfirmation" });
          break;
        case "CarConfirmation":
          if (this.hasCG && this.cgVehicles.length > 0) {
            this.$router.push({ name: "CarSelection" });
          } else if (this.$store.state.hasServiceSchedule == true) {
            this.$router.push({ name: "CarWithService" });
          } else if (this.$store.state.carByPlate.ktypes == null) {
            this.$store.dispatch("resetCarByPlate");
            this.$store.dispatch("clearHomepage");
            this.$store.dispatch("setHasServiceSchedule", false);
            this.$store.dispatch("resetHasServiceSchedule");
            this.$store.dispatch("clearDealerSelection");
            this.$store.dispatch("clearMarkingService");
            this.$router.push({ name: "Homepage" });
          } else {
            this.$router.push({ name: "CarVersionSelect" });
          }
          break;
        case "CarVersionSelect":
          if (this.$store.state.hasServiceSchedule == true) {
            this.$router.push({ name: "CarWithService" });
          } else {
            this.$store.dispatch("resetCarByPlate");
            this.$store.dispatch("resetCarByPlate");
            this.$store.dispatch("clearHomepage");
            this.$store.dispatch("setHasServiceSchedule", false);
            this.$store.dispatch("resetHasServiceSchedule");
            this.$store.dispatch("clearDealerSelection");
            this.$store.dispatch("clearMarkingService");
            this.$router.push({ name: "Homepage" });
          }
          break;
        case "CarWithService":
          this.$store.dispatch("resetCarByPlate");
          this.$store.dispatch("clearHomepage");
          this.$store.dispatch("setHasServiceSchedule", false);
          this.$store.dispatch("resetHasServiceSchedule");
          this.$store.dispatch("clearDealerSelection");
          this.$store.dispatch("clearMarkingService");
          this.$router.push({ name: "Homepage" });
          break;
        case "CarSelection":
          if (this.hasCG) {
            this.$store.dispatch("resetCarByPlate");
            this.$store.dispatch("clearHomepage");
            this.$store.dispatch("setHasServiceSchedule", false);
            this.$store.dispatch('resetHasServiceSchedule')
            this.$store.dispatch("clearDealerSelection");
            this.$store.dispatch("clearMarkingService");

            this.$router.push({ name: "Homepage" });
          } else {
            this.$store
              .dispatch(
                "getCorrectToken",
                this.selectedCarKtype,
                this.$store.state.carPlate
              )
              .then(() => {
                // Redefino os valores do item selecionado aqui
                this.$store.commit("SET_SELECTED_CAR_KTYPE", null);
                this.$router.push({ name: "CarVersionSelect" });
              })
              .catch((error) => {
                console.error("Erro ao obter o token correto:", error);
              });
          }
          break;
        case "PlateNotFound":
          this.$store.dispatch("resetCarByPlate");
          this.$router.push({ name: "Homepage" });

          break;
        default:
          this.$router.go(-1);
      }
    },
    navigateToStep(index) {
      if (index <= this.currentStepIndex) {
        const newRoute = this.steps[index].route;
        if (this.$route.name !== newRoute) {
          const stepDifference = this.currentStepIndex - index;
          this.$store.dispatch(
            "setCurrentStepIndex",
            this.currentStepIndex - stepDifference
          );
          if (this.$store.state.avancarCarSelection == true) {
            this.$store.dispatch(
              "setCurrentStepIndex",
              this.currentStepIndex + 1
            );
          }
          // Se a rota para a qual estamos navegando é DealerSelection, atualizamos a store com o revendedor selecionado
          if (newRoute === "DealerSelection") {
            // Adicionarei aqui a lógica para atualizar a store com o revendedor selecionado
            // Limpar os serviços
            this.$store.dispatch("clearServices");
          } else if (newRoute === "CarSelection") {
            this.$store.dispatch("setNavigatedFromHeader", true);
          }
          if (newRoute === "Homepage") {
            this.$store.dispatch("setHasServiceSchedule", false);
            this.$store.dispatch("resetHasServiceSchedule");
            this.$store.dispatch("clearDealerSelection");
            this.$store.dispatch("clearHomepage");
            /*  this.$store.dispatch('clearMarkingService'); */
          }
          const servicesIndex = this.steps.findIndex(
            (step) => step.route === "Services"
          );
          if (index < servicesIndex) {
            this.$store.dispatch("clearDealerSelection");
            this.$store.commit("RESET_SELECTED_SERVICES");
            this.$store.dispatch("clearServices");
          }
          this.$router.push({ name: newRoute });
        } else {
          console.log("voce ja esta nesta rota.");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Header";
</style>
