<template>
  <div>
    <button class="btn" type="button" @click="action()">{{ label }}</button>
  </div>
</template>
<script>
export default {
  name: "Button",
  props: {
    label: { type: String, default: "" },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  width: 300px;
  background: #fff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 0.9rem 0.5rem;
  outline: none;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  opacity: 0.7;
  transition: all ease-in-out 300ms;

  &:hover {
    opacity: 1;
    -webkit-box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.5);
  }
}
</style>
