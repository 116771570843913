import { country } from "@/services/country";
import { translations } from "@/localData/translations";

export function translateString(string) {
  return translations[country][string]
    ? translations[country][string]
    : translations["pt"][string];
}

export function formatNumber(value, separator) {
  return value
    .toString()
    .replace(/\s+/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export function formatPrice(value, decimals, currency, component) {
  if (isNaN(value)) {
    return value;
  } else if (value == 0) {
    if (component === "UpdatedResume") {
      return translateString("onRequest");
    } else {
      return translateString("free");
    }
  } else if (value == -2) {
    return "";
  } else if (value < 0) {
    return translateString("onRequest");
  }
  return Number(value).toFixed(decimals).replace(".", ",") + " " + currency;
}
export function getUniques(array, key) {
  const flag = {};
  const unique = [];

  array.forEach((el) => {
    if (!flag[el[key]]) {
      flag[el[key]] = true;
      unique.push(el[key]);
    }
  });
  return unique.sort();
}

export function groupServices(services, mobilityWash = false) {
  const groupedServices = [];
  let allServices = [];

  if (!mobilityWash) {
    allServices = services.servicesDesc.concat(services.servicesList);
  } else {
    services.map((el) => {
      if (el.groupid) {
        el.groupID = el.groupid;
      }
    });
    allServices = services;
  }
  const groups = getUniques(allServices, "groupID");
  groups.forEach((el) => {
    groupedServices[el] = [];
    allServices.forEach((service) => {
      if (service.groupID == el) {
        groupedServices[el].push(service);
      }
    });
  });

  return groupedServices;
}
