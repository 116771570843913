<template>
  <div :class="noData ? 'overlay-center overlay' : 'overlay'">
    <div class="popup">
      <div v-if="formResponse == ''" class="popup-container">
        <div class="popup-text">
          <p class="text-white">
            {{ translateString("notAvailableToContinue") }}
            <br />
            {{ translateString("tryLater") }}
          </p>
        </div>
        <Input
          id="name"
          :placeholder="translateString('name') + ' *'"
          inputStyle="round"
          @updateInput="updateInput($event, 'name')"
          type="text"
          autocomplete="name"
          :value="contactInfo.name"
        />
        <Input
          id="email"
          :placeholder="translateString('email') + ' *'"
          inputStyle="round"
          @updateInput="updateInput($event, 'email')"
          type="email"
          autocomplete="email"
          errorMsg="Insira um email válido"
          :value="contactInfo.email"
        />
        <Input
          id="phone"
          :placeholder="translateString('phone') + ' *'"
          inputStyle="round"
          @updateInput="updateInput($event, 'phone')"
          type="phone"
          autocomplete="tel"
          :errorMsg="translateString('insertValidPhone')"
          :value="contactInfo.phone"
        />
        <TextArea
          id="comments"
          :placeholder="translateString('comments')"
          inputStyle="round"
          @updateInput="updateInput($event, 'comments')"
          type="comments"
        />
        <Button
          id="sendErrorForm"
          :label="translateString('send')"
          :btnClass="ctaBtn"
          @action="send('sendErrorForm')"
        />
      </div>
      <div v-else>
        <div class="popup-text">
          <p class="text-white">
            {{ formResponse }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Input from "@/components/Inputs/Input.vue";
import TextArea from "@/components/Inputs/TextArea.vue";

import { validateInputs } from "@/functions/validations.js";
import { translateString } from "@/functions/functions.js";

export default {
  name: "PopUpFormError",
  components: {
    Button,
    Input,
    TextArea,
  },
  props: {
    noData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ctaBtn: "disabled ",
      contactInfo: this.$store.state.contactInfo,
      cgName: this.$store.state.cgName,
      cgEmail: this.$store.state.cgEmail,
      cgPhone: this.$store.state.cgPhone,
      obs: "",
      formResponse: "",
    };
  },
  computed: {
    errorFormReady() {
      return this.$store.state.errorFormReady;
    },
  },
  created() {
    if (this.cgName != "") {
      this.contactInfo.name = this.cgName;
    }
    if (this.cgEmail != "") {
      this.contactInfo.email = this.cgEmail;
    }
    if (this.cgPhone != "") {
      this.contactInfo.phone = this.cgPhone;
    }
  },
  methods: {
    translateString,
    close() {
      this.$emit("close");
    },
    send(id) {
      if (Object.values(this.contactInfo).some((x) => x !== null && x !== "")) {
        if (validateInputs(["email", "phone"]).length == 0) {
          this.$store.dispatch("selectOption", {
            selectedValue: "contactInfo",
            value: this.contactInfo,
          });
          this.$store.dispatch("selectOption", {
            selectedValue: "obs",
            value: this.obs,
          });
          this.$store.dispatch("selectOption", {
            selectedValue: "isLoading",
            value: id,
          });
          this.$store.dispatch("makeAppointment");
        }
      }
    },
    updateInput(value, field) {
      if (field != "comments") {
        this.contactInfo[field] = value;
      } else {
        this.obs = value;
      }
    },
  },
  watch: {
    contactInfo: {
      handler(contactInfo) {
        if (Object.values(contactInfo).some((x) => x === null || x === "")) {
          this.ctaBtn = "disabled ";
        } else {
          this.ctaBtn = "";
        }
      },
      deep: true,
    },
    errorFormReady() {
      if (this.errorFormReady == 1) {
        this.formResponse = translateString("formSuccess");
      } else if (this.errorFormReady == 0) {
        this.formResponse = translateString("formError");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/PopUp";
</style>
