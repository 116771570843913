<template>
  <div class="car-info-header row elm-mobile">
    <div class="car-avatar">
      <img :src="carImage" alt="car image"/>
    </div>
    <div>
      <p>
        <span v-if="carPlate" class="text-white-highlight">{{ carPlate }}</span
        ><br />
        <span v-if="carName" class="sm-text-white">{{ carName }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarInfoHeader",
  data() {
    return {
      carPlate: this.$store.state.carPlate,
      carName: this.$store.state.carName
        ? this.$store.state.carName
        : this.$store.state.carNF.brand + " " + this.$store.state.carNF.model,
      carImage: this.$store.state.carImage
        ? this.$store.state.carImage
        : require("@/assets/car_placeholder.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/UpdatedResume";
</style>
