import axios from "axios";

let authUrl;
let accessToken;

authUrl = process.env.VUE_APP_AUTH;
accessToken = process.env.VUE_APP_AUTH_ACCESS_TOKEN;

export async function authenticator(login) {
  try {
    const response = await axios.post(authUrl, login, {
      headers: {
        "Content-Type": "application/json",
        accessToken: accessToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
