<template>
  <div class="logoBrand" v-html="logo" @click="handleClick"></div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
  name: "LogoBrand",
  props: {
    logo: { type: String, default: "" },
  },
};
</script>

<style lang="scss">
.logoBrand {
  cursor: pointer;
  p {
    line-height: 1;
  }
  svg {
    height: 40px;
    /* max-width: 165px; */
    top: 4px;
    position: relative;
    margin-left: 10px; // Nao tinha isso
    margin-right: 10px; // Nao tinha isso

    path {
      fill: var(--primaryTextColor);
    }
    @include respond-to-max("md") {
      height: 40px;
    }
  }
}
</style>
