import { translateString } from "@/functions/functions.js";
import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "../views/Homepage.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Homepage",
    component: Homepage,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/" + translateString("vehicleWithService"),
    name: "CarWithService",
    component: () => import("../views/VehicleWithService.vue"),
  },
  {
    path: "/" + translateString("carVersionSelect"),
    name: "CarVersionSelect",
    component: () => import("../views/CarVersionSelect.vue"),
  },
  {
    path: "/" + translateString("carSelectionLink"),
    name: "CarSelection",
    component: () => import("../views/CarSelection.vue"),
  },
  {
    path: "/" + translateString("plateNotFoundLink"),
    name: "PlateNotFound",
    component: () => import("../views/PlateNotFound.vue"),
  },
  {
    path: "/" + translateString("carIdentificationLink"),
    name: "CarIdentification",
    component: () => import("../views/CarIdentification.vue"),
  },
  {
    path: "/" + translateString("carConfirmationLink"),
    name: "CarConfirmation",
    component: () => import("../views/CarConfirmation.vue"),
  },
  {
    path: "/" + translateString("dealerSelectionLink"),
    name: "DealerSelection",
    component: () => import("../views/DealerSelection.vue"),
  },
  {
    path: "/" + translateString("servicesLink"),
    name: "Services",
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/" + translateString("mobilityServicesLink"),
    name: "MobilityServices",
    component: () => import("../views/MobilityServices.vue"),
  },
  {
    path: "/" + translateString("washServicesLink"),
    name: "WashServices",
    component: () => import("../views/WashServices.vue"),
  },
  {
    path: "/" + translateString("scheduleLink"),
    name: "Schedule",
    component: () => import("../views/Schedule.vue"),
  },
  {
    path: "/" + translateString("contactInfoLink"),
    name: "ContactInfo",
    component: () => import("../views/ContactInfo.vue"),
  },
  {
    path: "/" + translateString("resumeLink"),
    name: "Resume",
    component: () => import("../views/Resume.vue"),
  },
  {
    path: "/" + translateString("confirmationLink"),
    name: "Confirmation",
    component: () => import("../views/Confirmation.vue"),
  },
  {
    path: "/" + translateString("errorFormLink"),
    name: "ErrorForm",
    component: () => import("../views/ErrorForm.vue"),
  },
  {
    path: "/:brand",
    name: "HomepageBrand",
    component: Homepage,
  },
  {
    path: "/:brand/login",
    name: "LoginBrand",
    component: Login,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
