import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import * as VueGoogleMaps from "vue2-google-maps";
import * as geolib from "geolib";
import "./registerServiceWorker";
Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

Vue.use(geolib);

Vue.use(VueMeta);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
