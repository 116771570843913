<template>
  <div>
    <label class="label" v-if="label" :for="id">{{ label }}</label>
    <input
      :type="type || 'text'"
      :id="id"
      :placeholder="placeholder"
      :class="inputStyle"
      :value="value"
      :autocomplete="autocomplete"
      @input="updateValue($event.target.value)"
      @change="changeValue($event.target.value)"
      @keyup="formatInput"
    />
    <br /><span class="error-message">{{ errorMsg }}</span>
  </div>
</template>

<script>
import { formatNumber, translateString } from "@/functions/functions.js";

export default {
  name: "Input",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    inputStyle: {
      type: String,
      default: "simple",
    },
    type: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: translateString("insertValidValue"),
    },
    autocomplete: {
      type: String,
      default: "on",
    },
  },
  methods: {
    translateString,
    updateValue: function (value) {
      this.$emit("updateInput", value);
    },
    changeValue: function (value) {
      this.$emit("changeInput", value);
    },
    formatInput: function (event) {
      if (this.type == "number" && event.target.value.length > 10) {
        event.target.value = formatNumber(event.target.value, " ");
      }
      if (event.keyCode === 13) {
        this.$emit("submit");
      }
    },
  },
};
</script>

<style lang="scss">
@import "/Inputs";
</style>
