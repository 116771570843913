<template>
  <h1 :class="titleStyle">{{ text }}</h1>
</template>

<script>
export default {
  name: "Header",
  props: {
    titleStyle: {
      type: String,
      default: "title",
    },
    text: {
      type: String,
    },
  },
};
</script>
