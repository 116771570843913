import { render, staticRenderFns } from "./UpdatedResume.vue?vue&type=template&id=91fb151e&scoped=true&"
import script from "./UpdatedResume.vue?vue&type=script&lang=js&"
export * from "./UpdatedResume.vue?vue&type=script&lang=js&"
import style0 from "./UpdatedResume.vue?vue&type=style&index=0&id=91fb151e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91fb151e",
  null
  
)

export default component.exports