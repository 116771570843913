export function capitalizeValues(array) {
  if (array.idbrand != 3) {
    //id da BMW
    const brandArr = array.brand.split("-");
    array.brand = "";
    brandArr.forEach((el) => {
      el = el.charAt(0).toUpperCase() + el.slice(1).toLowerCase();
      array.brand += el + "-";
    });

    array.brand = array.brand.slice(0, -1);
  }
  array.chassi =
    array.chassi.charAt(0).toUpperCase() + array.chassi.slice(1).toLowerCase();
  array.fuel =
    array.fuel.charAt(0).toUpperCase() + array.fuel.slice(1).toLowerCase();
  if (array.gearbox) {
    array.gearbox =
      array.gearbox.charAt(0).toUpperCase() +
      array.gearbox.slice(1).toLowerCase();
  }

  return array;
}

export function getCampaignInfo(allCampaigns, campaignID, dealerID, brandID) {
  return allCampaigns.find((campaign) => {
    return (
      campaign.uuid == campaignID &&
      campaign.idBrand == brandID &&
      campaign.idInstalation == dealerID
    );
  });
}

export function mapMaintenanceIdsForCampaigns(
  services,
  mobilityWashServices = []
) {
  let externalMaintID = "";
  let internalMaintID = "";
  let finalServices = [];

  const maintenanceServices = services.find((s) => {
    return s.id == -1;
  });

  if(maintenanceServices && maintenanceServices.servicesList){
    maintenanceServices.servicesList.find((m) => {
      if (m.external) {
        externalMaintID = m.id;
      } else {
        internalMaintID = m.id;
      }
    });
  }

  if (mobilityWashServices.length == 0) {
    finalServices = services.map((service) => {
      if (service.id != -1) {
        service.servicesList = service.servicesList.map((s) => {
          if (s.campaigns) {
            s.campaigns = s.campaigns.map((c) => {
              if (c.lst_services_parent && c.lst_services_parent.length > 0) {
                c.lst_services_parent = c.lst_services_parent.map((p) => {
                  if (p == -1) {
                    return externalMaintID;
                  } else if (p == -2) {
                    return internalMaintID;
                  }
                  return p;
                });
              }
              return c;
            });
          }
          return s;
        });
      }
      return service;
    });
  } else {
    finalServices = mobilityWashServices.map((s) => {
      if (s.campaigns) {
        s.campaigns = s.campaigns.map((c) => {
          if (c.lst_services_parent && c.lst_services_parent.length > 0) {
            c.lst_services_parent = c.lst_services_parent.map((p) => {
              if (p == -1) {
                return externalMaintID;
              } else if (p == -2) {
                return internalMaintID;
              }
              return p;
            });
          }
          return c;
        });
      }
      return s;
    });
  }

  return finalServices;
}
