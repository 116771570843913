<template>
  <div v-if="toNextStep" id="mso">
    <Loading type="screen" />
  </div>
  <div v-else class="home">
    <Header :isHeader="true" :showLogoBrand="true" />
    <Wrapper
      :hasBkgImg="true"
      classContainer="small-container left-container "
      :ismain="true"
    >
      <Title titleStyle="xl-title" :text="title" />
      <Input
        id="plate"
        :placeholder="translateString('insertPlate')"
        inputStyle="round"
        @updateInput="updateInput($event, 'plate')"
        type="plate"
        :errorMsg="translateString('insertValidPlate')"
        :value="carData.plate"
        @submit="next('getInfo')"
      />
      <Input
        id="kms"
        :placeholder="translateString('insertKms')"
        inputStyle="round"
        @updateInput="updateInput($event, 'kms')"
        type="text"
        :errorMsg="translateString('insertValidValueWNumber')"
        class="fake-placeholder"
        :value="carData.kms"
        @submit="next('getInfo')"
      />
      <Button
        id="getInfo"
        :label="translateString('continue')"
        :btnClass="ctaBtn"
        @action="next('getInfo')"
      />
      <p v-if="!hasCG && !cgError">
        <span class="sm-text-white">{{
          translateString("caetanoGoCheck")
        }}</span
        ><br />
        <span
          @click="openCaetanoGo('login')"
          class="text-white-highlight pointer"
          >{{ translateString("login") }}</span
        >
        <span class="sm-text-white">
          {{ translateString("caetanoGoOr") }}
        </span>
        <span
          @click="openCaetanoGo('registration')"
          class="text-white-highlight pointer"
          >{{ translateString("register") }}</span
        >
      </p>
      <p v-else-if="vehicles.length > 0">
        <span @click="myVehicles" class="text-white-highlight pointer">{{
          translateString("myVehicles")
        }}</span
        ><br />
      </p>
      <!--   <div class="service-branding row elm-desktop">
        <LogoDealer style="width: 200px" :logo="logoDealer" />
        Utilizado antes de alterar a logo do cliente para mostrar no Header.
      </div> -->
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Header from "@/components/Header/Header.vue";
import Input from "@/components/Inputs/Input.vue";
import Loading from "@/components/Loading/Loading.vue";
import Modal from "@/components/Modal/Modal.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";

/* import LogoDealer from "@/assets/LogoDealer.vue";*/

import { translateString } from "@/functions/functions.js";
import { validateInputs } from "@/functions/validations.js";

import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    Button,
    Header,
    Input,
    Loading,
    Modal,
    Title,
    Wrapper,
    /* LogoDealer, */
  },
  metaInfo() {
    var currentSite = window.location.hostname;
    if(this.$store.state.firstPath != "") {
      currentSite += '_' + this.$store.state.firstPath;
      currentSite = currentSite.toLowerCase();
    }
    const canonicalUrl = this.$store.state.canonicalUrls[currentSite];
    return {
      title: this.$store.state.seoTitle,
      meta: [
        {
          name: "title",
          content: this.$store.state.seoTitle,
        },
        {
          name: "description",
          content: this.$store.state.seoDescription,
        },
        {
          property: "og:title",
          content: this.$store.state.seoTitle,
        },
        {
          property: "og:description",
          content: this.$store.state.seoDescription,
        },
        {
          property: "og:image",
          content: this.$store.state.seoImage,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: canonicalUrl,
        },
      ],
    };
  },
  data() {
    return {
      title: this.$store.state.homepageTitle,
      carData: {
        plate: this.$route.query.plate ? this.$route.query.plate : "",
        kms: this.$route.query.km ? this.$route.query.km : "",
      },
      ctaBtn: "disabled ",
      /*logoDealer: this.$store.state.institutionalLogo,*/
      cgError: this.$store.state.cgError,
      toNextStep: false,
      brandLogo: "",
    };
  },
  computed: {
    ...mapState(["currentStepIndex"]),
    carInfoReady() {
      return this.$store.state.carByPlateReady;
    },
    allReady() {
      return this.$store.state.allReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    cgVehiclesReady() {
      return this.$store.state.cgVehiclesReady;
    },
    vehicles() {
      return this.$store.state.cgVehicles;
    },
    hasCG() {
      return this.$store.state.hasCG;
    },
    finalError() {
      return this.$store.state.finalError;
    },
  },
  created() {
    this.$store.dispatch("clearMarkingService");
    this.$store.dispatch("setHasServiceSchedule", false);
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    this.$store.dispatch("clearSelectedInfo", "Homepage");
    this.$store.dispatch("clearSelectedInfo", "Reset");
    this.ctaBtn = Object.values(this.carData).some(
      (x) => x === null || x === ""
    )
      ? "disabled "
      : "";
  },
  mounted() {
    this.$store.dispatch("clearSelectedInfo", "Homepage");
    this.$store.dispatch("setNavigatedFromHomepage", false);
    this.$store.dispatch("resetCarByPlate");
    if (this.carData.plate != "" && this.carData.kms != "") {
      this.toNextStep = true;
      this.next("getInfo");
    }

    //Tenho isto para que ao clicar em home page, ou seja, ao montar o componente, o currentStepIndex seja 1, assim nunca teremos o header quebrado.
    this.$store.dispatch("setCurrentStepIndex", 1);

    //Aqui eu faço a criaçao da tag canonical
    var currentSite = window.location.hostname;
    if(this.$store.state.firstPath != "") {
      currentSite += '_' + this.$store.state.firstPath;
      currentSite = currentSite.toLowerCase();
    }
    if (this.$store.state.canonicalUrls[currentSite]) {
      const canonicalUrl = this.$store.state.canonicalUrls[currentSite];
      document
        .querySelector('link[rel="canonical"]')
        .setAttribute("href", canonicalUrl);
    }
  },
  methods: {
    translateString,
    myVehicles() {
      if (this.cgVehiclesReady) {
        this.$store.dispatch("setAvancarCarSelection", true);
        this.$store.dispatch("setNavigatedFromHeader", true);

        this.$router.push({ name: "CarSelection" });
        this.$store.dispatch("setNavigatedFromHeader", true);
      } else {
        this.$store.dispatch("getCaetanoGoInfo");
      }
    },
    openCaetanoGo() {
      document.querySelectorAll("[data-idgo-widget]")[0].ifc.openPopup("login");
    },
    updateInput(value, field) {
      this.carData[field] = value;
    },
    next(id) {
      if (Object.values(this.carData).some((x) => x !== null && x !== "")) {
        if (validateInputs(["kms"]).length == 0) {
          this.$store.dispatch("getCarByPlate", {
            plate: this.carData.plate,
            kms: this.carData.kms.replace(/\s+/g, ""),
          });
          this.$store.dispatch("selectOption", {
            selectedValue: "isLoading",
            value: id,
          });
        } else {
          this.toNextStep = false;
        }
      }
    },
  },
  watch: {
    carData: {
      handler(carData) {
        if (Object.values(carData).some((x) => x === null || x === "")) {
          this.ctaBtn = "disabled ";
        } else {
          this.ctaBtn = "";
        }
      },
      deep: true,
    },
    carInfoReady() {
      if (this.carInfoReady == true) {
        if (this.$store.state.carCombo.length > 1) {
          //se tiver combo, ele entrará aqui
          this.$router.push({ name: "CarIdentification" });
        } else {
          //se só tiver um combo
          this.$store.dispatch("selectOption", {
            selectedValue: "carComboSelected",
            value: {
              comboLabel:
                this.$store.state.carCombo != ""
                  ? this.$store.state.carCombo[0].typeVehicleDesc
                  : "",
              comboId:
                this.$store.state.carCombo != ""
                  ? this.$store.state.carCombo[0].typeVehicleId
                  : 0,
              comboSelectedByUser: false,
            },
          });
          /* this.$router.push({ name: "CarWithService" }); */
          if (this.$store.state.carByPlate.ktypes != null) {
            // se tiver mais que um ID(ktype) para seleção de versão, entrará aqui
            this.$router.push({ name: "CarVersionSelect" });
          } else {
            //se não tiver mais que um ktype (ID), ele entrará aqui
            this.$router.push({ name: "CarWithService" });
            /* this.$router.push({ name: "CarConfirmation" }); */
          }
        }
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    allReady() {
      if (this.allReady == true) {
        this.$router.push({ name: "PlateNotFound" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
