/* eslint-disable no-console */

import { register } from "register-service-worker";

let serviceWorkerPath = "/service-worker.js";

/* if (process.env.NODE_ENV === "production") {
  serviceWorkerPath = `${process.env.VUE_APP_DOMAIN}service-worker.js`;
} else {
  serviceWorkerPath = `${process.env.VUE_APP_LOCAL_DOMAIN}service-worker.js`;

} */
register(serviceWorkerPath, {
  ready() {
    console.log(
      "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
  },
  registered() {
    console.log("Service worker has been registered.");
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    console.log("New content is available; Refresh...");
    navigator.serviceWorker.ready.then(() => {
      console.log("Refreshing in 1 second...");
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    });
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  }
});
