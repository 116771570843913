<template>
  <component :is="ismain ? 'main' : 'div'" class="wrapper">
    <slot name="sidebar"></slot>
    <div :class="classContainer + 'container'">
      <div class="content">
        <slot></slot>
      </div>
      <slot name="footer"></slot>
    </div>
    <template v-if="hasBkgImg">
      <div v-if="backgroundImageUrl" class="background-image">
        <img :src="backgroundImageUrl" :alt="title" class="img-cover" />
      </div>
      <div v-else class="background-image">
        <img src="@/assets/background.jpg" :alt="title" class="img-cover" />
      </div>
    </template>
  </component>
</template>

<script>
export default {
  name: "Wrapper",
  props: {
    hasBkgImg: { type: Boolean, default: false },
    classContainer: { type: String, default: "" },
    ismain: { type: Boolean, default: false },
    backgroundImageData: { type: Object },
  },
  computed: {
    title() {
      return this.$store.state.homepageTitle;
    },
  },
  data() {
    return {
      loadWPInfo: null,
      backgroundImageUrl: "",
      backgroundImage: this.$store.state.backgroundImage,
      backgroundImageMobile: this.$store.state.backgroundImageMobile,
    };
  },
  created() {
    if (this.hasBkgImg) {
      if (window.matchMedia("(max-width: 768px)").matches) {
        // se a tela tem menos de 768 pixels de largura, usarei a imagem mobile
        this.backgroundImageUrl = this.backgroundImageMobile;
      } else {
        // se a tela tem mais de 768 pixels de largura, usarei a imagem background padrão
        this.backgroundImageUrl = this.backgroundImage;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "/Wrapper";
</style>
