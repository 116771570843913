<template>
  <div>
    <label class="label" v-if="label" :for="id">{{ label }}</label>
    <textarea
      :id="id"
      :placeholder="placeholder"
      :class="inputStyle"
      :value="value"
      :maxlength="maxLength"
      @input="updateValue($event.target.value)"
      @keyup="formatInput"
      rows="4"
      cols="50"
    />
    <br /><span class="error-message">{{ errorMsg }}</span>
  </div>
</template>

<script>
import { translateString } from "@/functions/functions.js";

export default {
  name: "Input",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    inputStyle: {
      type: String,
      default: "simple",
    },
    type: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: translateString("insertValidValue"),
    },
    maxLength: {
      type: Number,
      default: 1000,
    },
  },
  methods: {
    translateString,
    updateValue: function (value) {
      this.$emit("updateInput", value);
    },
    formatInput: function (event) {
      if (event.keyCode === 13) {
        this.$emit("submit");
      }
    },
  },
};
</script>

<style lang="scss">
@import "/Inputs";
</style>
